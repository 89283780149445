<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <DashboardWidget
            title="Total Product Register"
            :value="totalNoProduct"
            icon="/media/svg/icons/Shopping/Box2.svg"
          />
        </div>

        <div class="col-lg-6">
          <DashboardWidget
            title="Total Quantity Holding"
            :value="totalNoQuantity"
            bg-color="bg-dark"
            icon="/media/svg/icons/Shopping/Loader.svg"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <DashboardWidget
            title="Highest Product Holding"
            :value="highestProductStored"
            bg-color="bg-info"
            icon="/media/svg/icons/General/Like.svg"
          />
        </div>

        <div class="col-lg-6">
          <DashboardWidget
            title="Highest Product Transactions"
            :value="highestProductMovement"
            bg-color="bg-warning"
            icon="/media/svg/icons/Shopping/Sort3.svg"
          />
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-12">
          <inbound-transaction-line-chart v-model="inboundTransaction" />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <outbound-transaction-line-chart v-model="outboundTransaction" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { getWarehouseDashboard } from "@/api/client-operation.api";

import DashboardWidget from "@/components/warehouse/DashboardWidget";
import InboundTransactionLineChart from "@/components/warehouse/InboundTransactionLineChart";
import OutboundTransactionLineChart from "@/components/warehouse/OutboundTransactionLineChart";

export default {
  components: { DashboardWidget, InboundTransactionLineChart, OutboundTransactionLineChart },
  setup() {
    const totalNoProduct = ref(0);
    const totalNoQuantity = ref(0);
    const highestProductStored = ref("");
    const highestProductMovement = ref("");

    const inboundTransaction = ref([]);
    const outboundTransaction = ref([]);

    onMounted(() => {
      getWarehouseDashboard().then(response => {
        const data = response.data;

        totalNoProduct.value = data.total_no_product;
        totalNoQuantity.value = data.total_no_quantity;
        highestProductStored.value = data.highest_product_stored;
        highestProductMovement.value = data.highest_product_movement;

        inboundTransaction.value = data.inbound_transaction_for_last_10_day;
        outboundTransaction.value = data.outbound_transaction_for_last_10_day;
      });
    });

    return {
      totalNoProduct,
      totalNoQuantity,
      highestProductStored,
      highestProductMovement,

      inboundTransaction,
      outboundTransaction
    };
  }
};
</script>
